import React from 'react'
import Helmet from 'react-helmet'

import { GenericLayout } from './../layouts/generic'

const audioURL = 'https://troisieme-auteur.disco.ac/e/p/7795698?download=false&s=S1YMVRp748PmkLI9fk36YymXmTc%3AgG76KeGr&artwork=true&color=%23000000&theme=white'

export default class Music extends React.Component<MusicProps> {
  constructor(props: MusicProps) {
    super(props)
  }

  public render() {
    return (
      <GenericLayout>
        <Helmet>
          <title>Music | Edouard Rigaudière - Film Composer</title>
          <meta property="og:title" content="Music | Edouard Rigaudière - Film Composer" />
          <meta name="twitter:title" content="Music | Edouard Rigaudière - Film Composer" />
        </Helmet>
        <main className="edrig-music">
          <div className="edrig-music__item">
            <iframe id="disco-playlist-7795698" name="disco-playlist-7795698" allowFullScreen frameBorder="0" className="disco-embed" src={audioURL} width="100%" height="100%" />
          </div>
        </main>
      </GenericLayout>
    )
  }
}

type MusicProps = {}
